.all-work {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 2.5rem;
  font-size: 1.2rem;
  margin: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 10px dotted white;
  border-right: 10px dotted white;
  border-bottom: 10px dotted white;
}

.odd {
  align-content: flex-start;
  background: linear-gradient(to right, rgba(222, 59, 208, 0.6), rgba(49, 227, 144, 0));
  border-top: 3px solid rgba(222, 59, 208, 1);
  border-left: 3px solid rgba(222, 59, 208, 1);
  border-bottom: 3px solid rgba(222, 59, 208, 1);
  border-radius: 8px 0px 8px 0px;
  box-shadow: 
  inset 20px 0 60px rgba(222, 59, 208, 1),
  -5px 0px 10px 5px rgba(222, 59, 208, 1);
  padding: 20px;
  margin: 30px;
  margin-right: 80px;
}

.odd a:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 10px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 30px rgba(222, 59, 208, 1), 0 0 40px rgba(222, 59, 208, 1), 0 0 50px rgba(222, 59, 208, 1), 0 0 60px rgba(222, 59, 208, 1), 0 0 70px rgba(222, 59, 208, 1);
  transition: 666ms;
}

.even {
  text-align: right;
  align-content: flex-end;
  background: linear-gradient(to left, rgba(102, 37, 177, 0.6), rgba(102, 37, 177, 0));
  border-top: 3px solid rgba(102, 37, 177, 1);
  border-right: 3px solid rgba(102, 37, 177, 1);
  border-bottom: 3px solid rgba(102, 37, 177, 1);
  border-radius: 0px 8px 0px 8px;
  box-shadow: 
  inset 20px 0 60px rgba(102, 37, 177, 1),
  5px 0px 10px 5px rgba(102, 37, 177, 1);
  padding: 20px;
  margin: 30px;
  margin-left: 80px;
}

.even a:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 10px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 30px rgba(102, 37, 177, 1), 0 0 40px rgba(102, 37, 177, 1), 0 0 50px rgba(102, 37, 177, 1), 0 0 60px rgba(102, 37, 177, 1), 0 0 70px rgba(102, 37, 177, 1);
  transition: 666ms;
}

a {
  font-size: 1.8rem;
}


@media only screen and (max-width: 850px) {

  .all-work {
    line-height: 2rem;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-right: 10px;
    margin: 20px;
    border-top: 5px dotted white;
    border-right: 5px dotted white;
    border-bottom: 5px dotted white;

  }

  .odd {
    margin: 20px;
    margin-right: 50px;
  }
  
  .even {
    margin: 20px;
    margin-left: 50px;
  }

}
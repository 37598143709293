.skills {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  padding: 30px 30px 0px 30px;
  margin: 20px;
  border-bottom: 10px dotted white;
  border-left: 10px dotted white;
}

.logo {
  text-align: center;
  margin: 20px;
}

.skill-logo {
  width: 100px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  -moz-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

@keyframes flicker {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgba(222, 59, 208, 1), 0 0 40px rgba(222, 59, 208, 1), 0 0 50px rgba(222, 59, 208, 1),
    inset 0px 0 30px rgba(222, 59, 208, 1),
    0px 0px 20px 10px rgba(222, 59, 208, 1);
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px rgba(102, 37, 177, 1), 0 0 40px rgba(102, 37, 177, 1), 0 0 50px rgba(102, 37, 177, 1), 0 0 60px rgba(102, 37, 177, 1),inset 0px 0 30px rgba(102, 37, 177, 1), 0px 0px 20px 10px rgba(102, 37, 177, 1);;
  }
}

@media only screen and (max-width: 850px) {

  .skills {
    padding: 0px;
    margin: 20px;
    border-bottom: 5px dotted white;
    border-left: 5px dotted white;
  }
  
  .logo {
    text-align: center;
    margin: 20px;
  }
  
  .skill-logo {
    width: 100px;
    padding: 10px;
  }

}
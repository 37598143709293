contact-form {
  display: flex;
  flex-direction: column;
}

.contact-header {
  box-shadow: 
  inset 20px 0 60px rgba(222, 59, 208, 1),
  0px 0px 10px 5px rgba(222, 59, 208, 1);
  text-align: center;
  font-size: 3rem;
}

label {
  font-size: 1.8rem;
  box-shadow: 
  inset 20px 0 60px rgba(102, 37, 177, 1),
  0px 0px 10px 5px rgba(102, 37, 177, 1);
  margin: 15px;
}

input {
  font-size: 1.8rem;
  font-family: "Quicksand", sans-serif;
  margin: 15px;
}

textarea {
  font-size: 1.8rem;
  font-family: "Quicksand", sans-serif;
  margin: 15px;
}

.inputBtn {
  border: none;
  color: rgb(255, 255, 255);
  font-family: "Unica One", sans-serif;
  font-size: 2.2rem;
  background-color: transparent;
  box-shadow: 
  inset 20px 0 60px rgba(222, 59, 208, 1),
  0px 0px 10px 5px rgba(222, 59, 208, 1);
  padding-left: 7px;
  cursor: pointer;
  margin: 15px;
}

.inputBtn:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 10px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 30px rgba(222, 59, 208, 1), 0 0 40px rgba(222, 59, 208, 1), 0 0 50px rgba(222, 59, 208, 1), 0 0 60px rgba(222, 59, 208, 1), 0 0 70px rgba(222, 59, 208, 1);
  transition: 666ms;
}


.about {
  margin: 30px;
  margin-bottom: 60px;
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: 1.4rem;
  width: 60vw;
  max-width: 800px;
  border-bottom: 10px dotted white;
  border-left: 10px dotted white;
}

@media only screen and (max-width: 850px) {

  .about {
    padding-top: 20px;
    width: 80vw;
    border-bottom: 5px dotted white;
    border-left: 5px dotted white;
    margin: 20px;
  }

}
html {
  box-sizing: border-box
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

*, 
*::before,
*::after { 

  box-sizing: inherit;
}

:root {
  font-size: 90%
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  background-image: url("../Images/foliage_2.jpeg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: white;
  font-family: "Quicksand", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.4rem;
  max-width: 1500px !important;
  margin: 0 auto;

}


a {
  font-family: "Unica One", sans-serif;
  text-decoration: none;
  color: white;
}

h1 {
  font-family: "Unica One", sans-serif;
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
  margin: 40px;
  width: 70%;
}

.tay {
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgba(222, 59, 208, 1), 0 0 40px rgba(222, 59, 208, 1), 0 0 50px rgba(222, 59, 208, 1), 0 0 60px rgba(222, 59, 208, 1), 0 0 70px rgba(222, 59, 208, 1);
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px rgba(102, 37, 177, 1), 0 0 40px rgba(102, 37, 177, 1), 0 0 50px rgba(102, 37, 177, 1), 0 0 60px rgba(102, 37, 177, 1), 0 0 70px rgba(102, 37, 177, 1), 0 0 80px rgba(102, 37, 177, 1);
  }
}

.header {
  margin: 20px;
}

.sprite {
  width: -50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 80px;
  margin-bottom: -190px;
}

img {
  padding: 50px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 
  inset 0px 0 30px rgba(102, 37, 177, 1),
  0px 0px 20px 10px rgba(102, 37, 177, 1);
}

.name {
  line-height: 1rem;
}

.typing {
  font-size: 1.4rem;
}

.about-divider {
  border-bottom: 3px solid white;
}


@media only screen and (max-width: 1500px) {

  .sprite {
    width: -50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 50px;
    margin-bottom: -190px;
  }

  img {
    padding: 30px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 
    inset 0px 0 30px rgba(102, 37, 177, 1),
    0px 0px 20px 10px rgba(102, 37, 177, 1);
  }

}

@media only screen and (max-width: 850px) {

  .sprite {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 20px;
    margin-bottom: 10px;
    margin-right: 20px;
  }

  img {
    padding: 20px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 
    inset 0px 0 30px rgba(102, 37, 177, 1),
    0px 0px 20px 10px rgba(102, 37, 177, 1);
  }

}